// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  //  apiUrl: 'http://111.118.177.155:3000/api/',
  //  apiUrl: 'https://devrunner.co.in/Runner_API_DEV/web/Admin_web/',
 //apiUrl: 'https://devrunner.co.in/Runner_API_DEV_MALL/web/Admin_web/'
apiUrl: 'https://app.therunner.co.in/Runner_API_DEV_MALL/index.php/web/Admin_web/'
  //apiUrl: 'http://localhost/runner_backend/web/admin_web/',
};
