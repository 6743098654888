import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, UserService, AuthenticationService } from '../../_services';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'email-forgot.component.html',
  styleUrls: ['email-forgot.component.scss']
})
export class EmailForgotComponent implements OnInit {
  emailForm: FormGroup;
  otpForm: FormGroup;
//   userCredentials= {
//     firstName: null
//   }; 
  loading = false;
  submitted = false;
  emailVerify= false;
  otpVerify = false;
  emailVerifydata:any = [];
  otpVerifydata:any = [];
  token = new FormControl('');
  otpEmail = new FormControl('');

  constructor(
      private formBuilder: FormBuilder,
      private router: Router,
      private authenticationService: AuthenticationService,
      private userService: UserService,
      private alertService: AlertService,
      private modalService: NgbModal
  ) {
      // redirect to home if already logged in
      if (this.authenticationService.currentUserValue) {
          this.router.navigate(['/']);
      }


  }


  ngOnInit() {

      this.emailForm = this.formBuilder.group({
        user_email: ['', [Validators.required, Validators.email]]
      });

 
    }

  
  
  // convenience getter for easy access to form fields
  get f() { return this.emailForm.controls; }
  get g() { return this.otpForm.controls; }

  onSubmit() {

    // if(!this.emailVerify){
    //   this.emailVerify = true;  
    // }
    // else
    // {
    //   this.emailVerify = false;
    // }

      this.submitted = true;

      // reset alerts on submit
      this.alertService.clear();

      // stop here if form is invalid
      if (this.emailForm.invalid) {
          return;
      }

      this.loading = true;
      this.userService.emailForgotPassword(this.f.user_email.value)
          .pipe(first())
          .subscribe(
              data => {
                this.emailVerifydata = data;
                  this.alertService.success('Please check your Email', true);
                  if(this.emailVerifydata.status == 1)
                  {
                    this.router.navigate(['forgot']);
                  }
                  //this.router.navigate(['/login']);
                  // localStorage.setItem("token", this.emailVerifydata.token);
                  // localStorage.setItem("email", this.f.user_email.value);
                  // this.emailVerify = true;
              },
              error => {
                switch (error.status) {
                  case 400:      //login
                  this.alertService.error('Email doesnt exists');
                  this.loading = false;
                  this.emailVerify = false;
                    break;
                  case 401:     //forbidden
                  this.alertService.error('Invalid Credentials');
                  this.loading = false;
                  this.emailVerify = false;
                    break;
                }
          
                //this.otpVerifydata = error;

              });
      
      
  }


  otpV(){
    
     
    this.otpForm.patchValue({
      token: localStorage.getItem('token'),
      email: localStorage.getItem('email')
    });
    if (this.otpForm.invalid) {
      return;
  }
   //console.log(this.otpForm.value);
    this.userService.forgotOtpVerification(this.otpForm.value)
    .pipe(first())
    .subscribe(
        data => {
            this.otpVerifydata = data;
            this.alertService.success('otp Verification Succefully', true);
            localStorage.setItem("token", this.otpVerifydata.reset_token);
            this.router.navigate(['/forgot']);
            this.emailVerify = true;
        },
        error => {

          switch (error.status) {
            case 400:      //login
            this.alertService.error('Invalid or OTP Expire');
            this.loading = false;
            this.emailVerify = false;
              break;
            case 401:     //forbidden
            this.alertService.error('Invalid Credentials');
            this.loading = false;
            this.emailVerify = false;
              break;
          }
        });  
  }
}

