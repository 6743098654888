
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../_models';
import { observable, Observable } from 'rxjs';
import { AllUsers } from './../_models/AllUsers';

@Injectable({ providedIn: 'root' })
export class MerchantService {

    constructor(private http: HttpClient) {
        if(localStorage.getItem('currentUser')){
            const mydata = JSON.parse(localStorage.getItem('currentUser'));
            const auth_token =  mydata.token;
            let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+auth_token });
            let options = { headers: headers };
        }
        
     }
   

    addMerchentBasicDetails(merchant_name,merchant_user_contact_number,merchant_email,merchant_spoc_name,merchant_spoc_contact_no,merchant_spoc_email_id,merchant_zip_code,merchant_country,merchant_address,merchant_state,merchant_city,mall_id,mall_floor, options){
               
        const params: HttpParams = new HttpParams()
        .set('merchant_name', merchant_name)
        .set('merchant_country_code', '+91')
        .set('merchant_user_contact_number', merchant_user_contact_number)
        .set('merchant_email', merchant_email)
        .set('merchant_spoc_name', merchant_spoc_name)
        .set('merchant_spoc_contact_no', merchant_spoc_contact_no)
        .set('merchant_spoc_email_id', merchant_spoc_email_id)
        .set('merchant_country', merchant_country)
        .set('merchant_zip_code', merchant_zip_code)
        .set('merchant_address', merchant_address)
        .set('merchant_state', merchant_state)
        .set('merchant_city', merchant_city)
        // .set('merchant_airport', '2')
        // .set('merchant_airport_terminal', '2')
        .set('mall_id', mall_id)
        .set('mall_floor',mall_floor);


        return this.http.post(`${environment.apiUrl}Merchant/Admin_Merchant/register_merchant_Basic`,  params , options);
    }

    updateMerchentBasicDetails(merchantId,merchant_name,merchant_user_contact_number,merchant_email,merchant_spoc_name,merchant_spoc_contact_no,merchant_spoc_email_id,merchant_zip_code,merchant_country,merchant_address,merchant_state,merchant_city,mall_id,mall_floor, options){
               
        const params: HttpParams = new HttpParams()
        .set('merchant_id', merchantId)
        .set('merchant_name', merchant_name)
        .set('merchant_country_code', '+91')
        .set('merchant_user_contact_number', merchant_user_contact_number)
        .set('merchant_email', merchant_email)
        .set('merchant_spoc_name', merchant_spoc_name)
        .set('merchant_spoc_contact_no', merchant_spoc_contact_no)
        .set('merchant_spoc_email_id', merchant_spoc_email_id)
        .set('merchant_country', merchant_country)
        .set('merchant_zip_code', merchant_zip_code)
        .set('merchant_address', merchant_address)
        .set('merchant_state', merchant_state)
        .set('merchant_city', merchant_city)
        // .set('merchant_airport', '2')
        // .set('merchant_airport_terminal', '2')
        .set('mall_id', mall_id)
        .set('mall_floor',mall_floor);
        


        return this.http.post(`${environment.apiUrl}Merchant/Admin_Merchant/update_merchant_Basic`,  params , options);
    }

    addMerchentBusinesDetails(merchantId,merchant_shop_name,merchant_company_name,merchant_shop_no,merchant_shop_category,merchant_shop_sub_category,merchant_shop_lic_no,merchant_cin_no,merchant_food_lic_no,options){
               
        const params: HttpParams = new HttpParams()
        .set('merchant_id', merchantId)
        .set('merchant_shop_name', merchant_shop_name)
        .set('merchant_company_name', merchant_company_name)
        .set('merchant_shop_no', merchant_shop_no)
        .set('merchant_shop_category', merchant_shop_category)
        .set('merchant_shop_sub_category', merchant_shop_sub_category)
        .set('merchant_shop_lic_no', merchant_shop_lic_no)
        .set('merchant_cin_no', merchant_cin_no)
        .set('merchant_food_lic_no', merchant_food_lic_no);


        return this.http.post(`${environment.apiUrl}Merchant/Admin_Merchant/register_merchant_Business`,  params , options);
    }

    updateMerchentBusinesDetails(merchantId,merchant_shop_name,merchant_company_name,merchant_shop_no,merchant_shop_category,merchant_shop_sub_category,merchant_shop_lic_no,merchant_cin_no,merchant_food_lic_no,options){
               
        const params: HttpParams = new HttpParams()
        .set('merchant_id', merchantId)
        .set('merchant_shop_name', merchant_shop_name)
        .set('merchant_company_name', merchant_company_name)
        .set('merchant_shop_no', merchant_shop_no)
        .set('merchant_shop_category', merchant_shop_category)
        .set('merchant_shop_sub_category', merchant_shop_sub_category)
        .set('merchant_shop_lic_no', merchant_shop_lic_no)
        .set('merchant_cin_no', merchant_cin_no)
        .set('merchant_food_lic_no', merchant_food_lic_no);


        return this.http.post(`${environment.apiUrl}Merchant/Admin_Merchant/update_merchant_Business`,  params , options);
    }


    addMerchentBankDetails(merchantId,bank_name,bank_ac_no,bank_ac_holder_name,bank_ifsc_code,bank_branch,bank_gst_no,pan_no,tan_no,options){
               
        const params: HttpParams = new HttpParams()
        .set('merchant_id', merchantId)
        .set('bank_name', bank_name)
        .set('bank_ac_no', bank_ac_no)
        .set('bank_ac_holder_name', bank_ac_holder_name)
        .set('bank_ifsc_code', bank_ifsc_code)
        .set('bank_branch', bank_branch)
        .set('bank_gst_no', bank_gst_no)
        .set('pan_no', pan_no)
        .set('tan_no', tan_no);


        return this.http.post(`${environment.apiUrl}Merchant/Admin_Merchant/register_merchant_Bank`,  params , options);
    }

    
    updateMerchentBankDetails(merchantId,bank_name,bank_ac_no,bank_ac_holder_name,bank_ifsc_code,bank_branch,bank_gst_no,pan_no,tan_no,options){
               
        const params: HttpParams = new HttpParams()
        .set('merchant_id', merchantId)
        .set('bank_name', bank_name)
        .set('bank_ac_no', bank_ac_no)
        .set('bank_ac_holder_name', bank_ac_holder_name)
        .set('bank_ifsc_code', bank_ifsc_code)
        .set('bank_branch', bank_branch)
        .set('bank_gst_no', bank_gst_no)
        .set('pan_no', pan_no)
        .set('tan_no', tan_no);


        return this.http.post(`${environment.apiUrl}Merchant/Admin_Merchant/update_merchant_Bank`,  params , options);
    }

    documentsUpload(merchantId, profile_photo: File,pan_photo: File,tan_photo: File,cin_file: File ,gst_file: File,fssia_certificate: File,business_logo: File,cancel_cheque: File,options){
        let formParams = new FormData();
        formParams.append('merchant_id', merchantId)
        formParams.append('profile_image', profile_photo)
        formParams.append('business_logo', business_logo)
        formParams.append('pan_doc', pan_photo)
        formParams.append('tan_doc', tan_photo)
        formParams.append('shop_doc', cin_file)
        formParams.append('gst_doc', gst_file)
        formParams.append('fsssia_lic_doc', fssia_certificate)
        formParams.append('bank_doc', cancel_cheque);

        return this.http.post(`${environment.apiUrl}Merchant/Admin_Merchant/upload_documents`,  formParams , options);
    }
    
    updatesUpload(merchantId, profile_photo: File,pan_photo: File,tan_photo: File,cin_file: File ,gst_file: File,fsssia_lic_doc: File,business_logo: File,cancel_cheque: File,options){
        let formParams = new FormData();
        formParams.append('merchant_id', merchantId)
        formParams.append('profile_image', profile_photo)
        formParams.append('business_logo', business_logo)
        formParams.append('pan_doc', pan_photo)
        formParams.append('tan_doc', tan_photo)
        formParams.append('shop_doc', cin_file)
        formParams.append('gst_doc', gst_file)
        formParams.append('fsssia_lic_doc', fsssia_lic_doc)
        formParams.append('bank_doc', cancel_cheque);

        return this.http.post(`${environment.apiUrl}Merchant/Admin_Merchant/update_documents`,  formParams , options);
    }

    commissionDetails(merchantId,merchant_commission, Agreement_doc: File,options){
        let formParams = new FormData();
        formParams.append('merchant_id', merchantId)
        formParams.append('merchant_commission', merchant_commission)
        formParams.append('Agreement_doc', Agreement_doc);

        return this.http.post(`${environment.apiUrl}Merchant/Admin_Merchant/register_commission`,  formParams , options);
    }

    updateCommissionDetails(merchantId,merchant_commission, Agreement_doc: File,options){
        let formParams = new FormData();
        formParams.append('merchant_id', merchantId)
        formParams.append('merchant_commission', merchant_commission)
        formParams.append('Agreement_doc', Agreement_doc);

        return this.http.post(`${environment.apiUrl}Merchant/Admin_Merchant/update_commission`,  formParams , options);
    }

    getAllMerchants(options) {
        return this.http.get(`${environment.apiUrl}/Merchant/Admin_Merchant/merchant_list?terminal_id=2`, options);
    }

    merchantStateList(id,options){
        return this.http.get(`${environment.apiUrl}State_Mgt/get_State?country_id=${id}`, options);
    }

    merchantCityList(id,options){
        return this.http.get(`${environment.apiUrl}City_Mgt/get_city?city_id=${id}`, options);
    }

    merchantRevenuMallList(options){
        return this.http.get(`${environment.apiUrl}/Merchant/Admin_Merchant/get_revenue_mall_list`, options);
    }

    merchantContryList(options){
        return this.http.get(`${environment.apiUrl}Country_Mgt/get_Countries`, options);
    }
    merchantCategoryList(options){
        return this.http.get(`${environment.apiUrl}/Merchant/Admin_Merchant/category_list`, options);
    }
    merchantSubCategoryList(id, options)
    {
        return this.http.get(`${environment.apiUrl}Merchant/Admin_Merchant/sub_category_list?category_id=${id}`, options);
    }
    merchantMallList(id,options)
    {
        return this.http.get(`${environment.apiUrl}Merchant/Admin_Merchant/mall_list?city_id=${id}`, options);
    }
    merchantFloorList(id, options)
    {
        return this.http.get(`${environment.apiUrl}Merchant/Admin_Merchant/mall_floor?mall_id=${id}`, options);
    }
    merchantEdit(id, options){
        return this.http.get(`${environment.apiUrl}Merchant/Admin_Merchant/merchant_edit?merchant_id=${id}`, options);
    }

    merchantRevenue(id,options)
    {
       return this.http.get(`${environment.apiUrl}Merchant/Admin_Merchant/get_todays_total_order?merchant_id=${id}`, options);
    }

    merchantRevenueByDate(fromdate,todate,id,options)
    {
        const params: HttpParams = new HttpParams()
        .set('fromdate', fromdate)
        .set('todate', todate)
        .set('merchant_id', id)
                
       return this.http.post(`${environment.apiUrl}Merchant/Admin_Merchant/get_date_total_order`, params, options);
    }
   
}