
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../_models';
import { observable, Observable } from 'rxjs';
import { AllUsers } from './../_models/AllUsers';

@Injectable({ providedIn: 'root' })
export class UserService {

    constructor(private http: HttpClient) {
        if(localStorage.getItem('currentUser')){
            const mydata = JSON.parse(localStorage.getItem('currentUser'));
            const auth_token =  mydata.token;
            let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+auth_token });
            let options = { headers: headers };
        }
        
     }
    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }

    register(user: User) {
        return this.http.post(`${environment.apiUrl}auth/register`, user);
    }

    delete(id: number) {
        return this.http.delete(`${environment.apiUrl}/users/${id}`);
    }

    emailVerification(user: User){   
        return this.http.post(`${environment.apiUrl}auth/check/email`, user);
    }

    otpVerification(user: User){   
        return this.http.post(`${environment.apiUrl}auth/verify/email`, user);
    }

    emailForgotPassword(email){   
        const params: HttpParams = new HttpParams()
        .set('user_email', email);
        
        return this.http.post(`${environment.apiUrl}Admin_Login/forgot_password_request_using_email`, params);
    }

    forgotOtpVerification(user: User){   
        return this.http.post(`${environment.apiUrl}auth/verify/fpcode`, user);
    }

    forgotPassword(user: User){   
        return this.http.patch(`${environment.apiUrl}Admin_Login/change_and_verify_forgot_password/`,user);
    }

    fetchUser(options){
        return this.http.get<User[]>(`${environment.apiUrl}user`, options);
    }

    updateUser(user: User, options){
    return this.http.put<User[]>(`${environment.apiUrl}user`, user, options);
    }

    newEmail(user: User, options){
        return this.http.post<User[]>(`${environment.apiUrl}user/check/new-email`, user, options);
        }
     
    otpVerify(user: User, options){
        return this.http.put<User[]>(`${environment.apiUrl}user/update/email`, user, options);
        }  
        
    resetPassword(user: User, options){
            return this.http.put<User[]>(`${environment.apiUrl}user/update/password`, user, options);
            }      
}