
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Products } from '../_models/products';
import { observable, Observable } from 'rxjs';
import { AllUsers } from './../_models/AllUsers';

@Injectable({ providedIn: 'root' })
export class ProductService {

    constructor(private http: HttpClient) {
        if(localStorage.getItem('currentUser')){
            const mydata = JSON.parse(localStorage.getItem('currentUser'));
            const auth_token =  mydata.auth_token;
            let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+auth_token,
            'App-Id': '6124a1dec83ed94d9cca372e',
            'App-Live': 'false'});
            let options = { headers: headers };
        }
        
     }
     getAllProducts(options) {
        return this.http.get<Products[]>(`${environment.apiUrl}/products`, options);
    }

    register(user: Products) {
        return this.http.post(`${environment.apiUrl}auth/register`, user);
    }

 
}