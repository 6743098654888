import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, UserService, AuthenticationService } from '../../_services';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'email-verification.component.html'
})
export class EmailVerificationComponent implements OnInit {
  emailForm: FormGroup;
  otpForm: FormGroup;
//   userCredentials= {
//     firstName: null
//   }; 
  loading = false;
  submitted = false;
  emailVerify= false;
  otpVerify = false;
  emailVerifydata:any = [];
  emailRegEx:string;
  token = new FormControl('');
  otpEmail = new FormControl('');

  constructor(
      private formBuilder: FormBuilder,
      private router: Router,
      private authenticationService: AuthenticationService,
      private userService: UserService,
      private alertService: AlertService,
      private modalService: NgbModal
  ) {
      // redirect to home if already logged in
      if (this.authenticationService.currentUserValue) {
          this.router.navigate(['/']);
      }


  }


  ngOnInit() {
    this.emailRegEx = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'
      this.emailForm = this.formBuilder.group({
          email: ['', [Validators.required, Validators.email]]
      });

      this.otpForm = this.formBuilder.group({
        otp_code: [null, [Validators.required, Validators.min(6)]],
        email:[''],
        token:['']
    });
 
    }

  
  
  // convenience getter for easy access to form fields
  get f() { return this.emailForm.controls; }
  get g() { return this.otpForm.controls; }

  onSubmit() {

    // if(!this.emailVerify){
    //   this.emailVerify = true;  
    // }
    // else
    // {
    //   this.emailVerify = false;
    // }

      this.submitted = true;

      // reset alerts on submit
      this.alertService.clear();

      // stop here if form is invalid
      if (this.emailForm.invalid) {
          return;
      }

      this.loading = true;
      this.userService.emailVerification(this.emailForm.value)
          .pipe(first())
          .subscribe(
              data => {
                this.emailVerifydata = data;
                  this.alertService.success('Please check your Email', true);
                  //this.router.navigate(['/login']);
                  localStorage.setItem("token", this.emailVerifydata.token);
                  localStorage.setItem("email", this.f.email.value);
                  this.emailVerify = true;
              },
              error => {
                  this.alertService.error(error);
                  this.loading = false;
                  this.emailVerify = false;
              });
      
      
  }


  otpV(){
    
           // stop here if form is invalid
       if (this.otpForm.invalid) {
            return;
        }
    this.otpForm.patchValue({
      token: localStorage.getItem('token'),
      email: localStorage.getItem('email')
    });
    console.log(this.otpForm);
    this.userService.otpVerification(this.otpForm.value)
    .pipe(first())
    .subscribe(
        data => {
            this.alertService.success('otp Verification Succefully', true);
            this.router.navigate(['/register']);
            this.emailVerify = true;
        },
        error => {
          switch (error.status) {
            case 400:      //login
            this.alertService.error('Invalid or OTP is Expire');
            this.loading = false;
            this.emailVerify = false;
              break;
            case 401:     //forbidden
            this.alertService.error('Invalid Credentials');
            this.loading = false;
            this.emailVerify = false;
              break;
          }
        });  
  }
}

