import { AddAppComponent } from './views/addApp/addApp.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { ForgotComponent } from './views/forgotpassword/forgot.component';
import { EmailVerificationComponent } from './views/email-verification/email-verification.component';
import { EmailForgotComponent } from './views/forgot-email/email-forgot.component';
import { PrivacyPolicyComponent } from './views/privacy_policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './views/terms_condition/terms_condition.component';

import { AuthGuard } from './_helpers';

export const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'login',
  //   component: LoginComponent,
  //  // pathMatch: 'full',
  //   //loadChildren: () => import('./views/login/login.module').then( m => m.LoginModule )
  // },
 
  {
    path: '',
    redirectTo: 'revenue',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    //loadChildren: () => import('./views/login/login.module').then( m => m.LoginModule )
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  // {
  //   path: 'login',
  //   component: LoginComponent,
  //   loadChildren: () => import('./views/login/login.module').then( m => m.LoginModule )
  // },
  // {
  //   path: 'login',
  //   //component: LoginComponent,
  //   data: {
  //     title: 'Login Page'
  //   },
  //   loadChildren: () => import('./views/login/login.module').then( m => m.LoginModule )
  // },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'email',
    component: EmailVerificationComponent,
    data: {
      title: 'Email Page'
    }
  },
  {
    path: 'forgot',
    component: ForgotComponent,
    data: {
      title: 'Forgot Page'
    }
  },
  {
    path: 'forgot-email',
    component: EmailForgotComponent,
    data: {
      title: 'Forgot Password'
    }
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: {
      title: 'Privacy Policy'
    }
  },
  {
    path: 'terms_and_condition',
    component: TermsAndConditionsComponent,
    data: {
      title: 'Terms & Condition'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    canActivate: [AuthGuard],
    children: [

      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
 
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      },
      {
        path: 'addapp',
        loadChildren: () => import('./views/addApp/addApp.module').then(m => m.AddAppModule)
      },
      {
        path: 'addbank',
        loadChildren: () => import('./views/addBank/addBank.module').then(m => m.AddBankModule)
      },
      {
        path: 'addupi',
        loadChildren: () => import('./views/addUpi/addUpi.module').then(m => m.AddUpiModule)
      },
      {
        path: 'user-profile',
        loadChildren: () => import('./views/user-profile/user-profile.module').then(m => m.UserProfileModule)
      },
      {
        path: 'purchase-video',
        loadChildren: () => import('./views/purchase-video/purchase-video.module').then(m => m.purchaseVideoModule)
      },
      {
        path: 'revenue',
        loadChildren: () => import('./views/revenue/revenue.module').then(m => m.RevenueModule)
      },
      {
        path: 'video-share',
        loadChildren: () => import('./views/video-share/video-share.module').then(m => m.VideoShareModule)
      },
      {
        path: 'downline-details',
        loadChildren: () => import('./views/downline-details/downline-details.module').then(m => m.DownlineDetailsModule)
      },
      {
        path: 'coupon-management',
        loadChildren: () => import('./views/coupon-management/coupon-management.module').then(m => m.CouponManagementModule)
      },
      {
        path: 'report-history',
        loadChildren: () => import('./views/report-history/report-history.module').then(m => m.ReportHistorysModule)
      },
      {
        path: 'app-details/:id',
        pathMatch: 'full',
        loadChildren: () => import('./views/app-details/app-details.module').then(m => m.AppDetailsModule)
      },
      {
        path: 'products',
        loadChildren: () => import('./views/products/products.module').then(m => m.productsModule)
      },
      {
        path: 'filter',
        loadChildren: () => import('./views/filter/filter.module').then(m => m.FilterModule)
      },
      {
        path: 'order-history',
        loadChildren: () => import('./views/order-history/order-history.module').then(m => m.OrderHistoryModule)
      },
      {
        path: 'feedback',
        loadChildren: () => import('./views/feedback/feedback.module').then(m => m.FeedbackModule)
      },
      {
        path: 'order',
        loadChildren: () => import('./views/order/order.module').then(m => m.OrderModule)
      },
      {
        path: 'order-history',
        loadChildren: () => import('./views/order-history/order-history.module').then(m => m.OrderHistoryModule)
      },
      {
        path: 'merchant-edit/:id',
        pathMatch: 'full',
        loadChildren: () => import('./views/merchant_edit/merchant-edit.module').then(m => m.MerchantEditModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' } ) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
