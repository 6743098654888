import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, UserService, AuthenticationService } from '../../_services';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'terms_condition.component.html',
  styleUrls: ['terms_condition.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
 

  constructor(
    
      private router: Router,
      private authenticationService: AuthenticationService,
      private alertService: AlertService,
      private modalService: NgbModal
  ) {
      // redirect to home if already logged i


  }


  ngOnInit() {

      
    }

  
  
}

