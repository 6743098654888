
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../_models';
import { observable, Observable } from 'rxjs';
import { AllUsers } from './../_models/AllUsers';

@Injectable({ providedIn: 'root' })
export class CouponService {

    constructor(private http: HttpClient) {
        if(localStorage.getItem('currentUser')){
            const mydata = JSON.parse(localStorage.getItem('currentUser'));
            const auth_token =  mydata.token;
            let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+auth_token });
            let options = { headers: headers };
        }
        
     }
   
     getAllCoupons(options) {
        const params: HttpParams = new HttpParams()
        .set('status_code', '1');
        return this.http.post(`${environment.apiUrl}Coupons/Admin_coupon/coupon_filter`,params, options);
    }

    couponFilterList(filterId, options){
        const params: HttpParams = new HttpParams()
        .set('status_code', filterId);

        return this.http.post(`${environment.apiUrl}Coupons/Admin_coupon/coupon_filter`, params, options);
    }

    orderView(id, options){
        return this.http.get(`${environment.apiUrl}/Order/Admin_order/order_edit?order_id=${id}`, options);
    }

    addCoupon(coupon_name,coupon_type,coupon_value,min_amount,max_amount,coupon_desc,form_date,to_date,options){
               
        const params: HttpParams = new HttpParams()
        .set('coupon_name', coupon_name)
        .set('coupon_type', coupon_type)
        .set('coupon_value', coupon_value)
        .set('min_amount', min_amount)
        .set('max_amount', max_amount)
        .set('coupon_desc', coupon_desc)
        .set('form_date', form_date)
        .set('to_date', to_date);

        return this.http.post(`${environment.apiUrl}Coupons/Admin_coupon/add_coupon`,  params , options);
    }

    updateCouponStatus(coupon_id,status_code,options){
               
        const params: HttpParams = new HttpParams()
        .set('coupon_id', coupon_id)
        .set('status_code', status_code)


        return this.http.post(`${environment.apiUrl}Coupons/Admin_coupon/coupon_approve`,  params , options);
    }

    getCouponDetails(id, options){
        return this.http.get(`${environment.apiUrl}Coupons/Admin_coupon/coupon_edit/?coupon_id=${id}`, options);
    }

    updateReason(reason, status_code, coupon_id, options)
    {
        const params: HttpParams = new HttpParams()
        .set('coupon_reason', reason)
        .set('status_code', status_code)
        .set('coupon_id', coupon_id);

        return this.http.post(`${environment.apiUrl}Coupons/Admin_coupon/update_reason`,  params , options);
    }

    feedbackList(options){
        return this.http.get(`${environment.apiUrl}Coupons/Admin_coupon/feedback_list`, options);
    }

    feedbackView(id, options){
        return this.http.get(`${environment.apiUrl}Coupons/Admin_coupon/feedback_edit/?order_id=${id}`, options);
    }


   
}