
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../_models';
import { observable, Observable } from 'rxjs';
import { AllUsers } from './../_models/AllUsers';

@Injectable({ providedIn: 'root' })
export class OrderService {

    constructor(private http: HttpClient) {
        if(localStorage.getItem('currentUser')){
            const mydata = JSON.parse(localStorage.getItem('currentUser'));
            const auth_token =  mydata.token;
            let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+auth_token });
            let options = { headers: headers };
        }
        
     }
   
     getAllOrder(options) {
        return this.http.get(`${environment.apiUrl}/Order/Admin_order/order_list`, options);
    }

    getTodaysOrder(options) {
        return this.http.get(`${environment.apiUrl}/Order/Admin_order/order_todays_list`, options);
    }

    orderFilterList(filterId, options){
        const params: HttpParams = new HttpParams()
        .set('status_code', filterId);

        return this.http.post(`${environment.apiUrl}/Order/Admin_order/order_filter`, params, options);
    }

    orderTodaysFilterList(filterId, options){
        const params: HttpParams = new HttpParams()
        .set('status_code', filterId);

        return this.http.post(`${environment.apiUrl}/Order/Admin_order/order_todays_filter`, params, options);
    }
    orderView(id, options){
        return this.http.get(`${environment.apiUrl}/Order/Admin_order/order_edit?order_id=${id}`, options);
    }
   
}