import { AuthenticationService } from './../../_services/authentication.service';
import { Component, Injectable} from '@angular/core';
import { navItems } from '../../_nav';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../_models';
import { Router } from '@angular/router';
import { UserService } from '../../_services';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators, FormControl } from '@angular/forms';
import { AlertService } from '../../_services';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ConfirmedValidator } from '../../confirmed.validator';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls:['default-layout.component.css']
})
export class DefaultLayoutComponent {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  submenu: boolean = true;
  options:any;
  closeModal: string;
  submitted= false
  reserPasswordForm:FormGroup;



  constructor(
    private http: HttpClient,
    private router: Router,
    private auth:AuthenticationService,
    private userService: UserService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal
    ) {
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
      this.currentUser = this.currentUserSubject.asObservable();

      const mydata = JSON.parse(localStorage.getItem('currentUser'));
      const auth_token =  mydata.auth_token;
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+auth_token });
      this.options = { headers: headers };
  }

  ngOnInit(): void {
    
    this.reserPasswordForm = this.formBuilder.group({
      old_password: ['', Validators.required],
      password: ['', Validators.required],
      confirm_password: ['', Validators.required],
      
    },{ 
      validator: ConfirmedValidator('password', 'confirm_password')
    });


  }
  triggerModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });

  }

  get f(){
    return this.reserPasswordForm.controls;
  }
  public get currentUserValue(): User {
      return this.currentUserSubject.value;
  }
  public sidebarMinimized = false;
  public navItems = navItems;

  toggleMinimize(e) {
    this.sidebarMinimized = e;
    if(!this.submenu){
      this.submenu = true;  
    }
    else
    {
      this.submenu = false;
    }
    
  }

  logout() {
    this.auth.logout();
  }

  resetPassword(){

    
    this.submitted = true;

             // stop here if form is invalid
             if (this.reserPasswordForm.invalid) {
              return;
          }
    this.userService.resetPassword(this.reserPasswordForm.value, this.options).subscribe(
      data => {
        this.alertService.success('Password Update Succefully');
        window.setTimeout(function(){location.reload()},1000);
        this.logout();
    },
    error => {
      switch (error.status) {
        case 400:      //login
        this.alertService.error('Your current password is wrong');
          break;
        case 401:     //forbidden
        this.alertService.error('Invalid Credentials');

          break;
      }
       // this.loading = false;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
}
