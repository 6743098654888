import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService, UserService } from '../../_services';
import { first } from 'rxjs/operators';
import { ConfirmedValidator } from '../../confirmed.validator';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'forgot.component.html',
  styleUrls: ['forgot.component.scss']
})
export class ForgotComponent {
  token = new FormControl('');
  resetForm: FormGroup;
  submitted = false;
  loading = false;

  constructor(
     private router: Router,
     private formBuilder: FormBuilder,
     private http: HttpClient,
     private alertService: AlertService,
     private userService: UserService,
     ) { }

  ngOnInit() {

    this.resetForm = this.formBuilder.group({
      user_password: ['', Validators.required],
      user_forgot_password: ['', Validators.required],
      user_forgot_code: ['', Validators.required],
      user_email: ['']

    },{ 
      validator: ConfirmedValidator('user_password', 'user_forgot_password')
    });

  }
  
  get f() { return this.resetForm.controls; }

  forgotpassword(){

    this.resetForm.patchValue({
      user_email: localStorage.getItem('email')
    });
    // console.log(this.resetForm);
    // console.log('http://35.176.174.157/api/auth/resetpwd/',this.f.email.value);
    
    this.submitted = true;
    if (this.resetForm.invalid) {
      return;
  }


    this.userService.forgotPassword(this.resetForm.value)
    .pipe(first())
    .subscribe(
        data => {
          
            this.alertService.success('Password Change Succefully', true);
            this.router.navigate(['/login'])
        },
        error => {
                    
          switch (error.status) {
            case 400:      //login
            this.alertService.error('Your token is invalid or expired OR username doesnt exist');
            this.loading = false;
           // this.emailVerify = false;
              break;
            case 401:     //forbidden
            this.alertService.error('Invalid Credentials');
            this.loading = false;
            //this.emailVerify = false;
              break;
          }
        });
      //this.router.navigate(['./register']);
      
  }

}
